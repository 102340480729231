import React, { Component } from 'react';
import AuthLoading from "./AuthLoading";

export default (importComponent)=>{

     return class Aysnc extends Component {

        state = {
            component: null,
            loading:true
        }

        componentDidMount() {
            importComponent().then(component => {
                this.setState({
                    component: component.default,
                    loading:false
                })
            })
        }



        render() {
          
            if(this.state.loading){
                return <AuthLoading/>
            }
            const LayoutComponent = this.state.component;
            return <LayoutComponent {...this.props}/>
        }
    }
}