import asyncComponent from "../components/AsyncComponent";
export default [
    {
        path: "/login",
        title: "登录",
        component: asyncComponent(() => import("../pages/login"))
    },
    {
        path:"/demo/:id",
        component:asyncComponent(()=>import("../pages/demo")),
        title:"测试"
    },
    {
        path:"/:user/bindGoogleCode",
        title:"绑定谷歌验证",
        component:asyncComponent(() => import("../pages/login/bindGoogleCode"))
    },
    {
        path:"/payout/voucher/:orderNo",
        title:"代付凭证",
        component:asyncComponent(()=>import("../pages/withdraw/voucher"))
    },
    {
        path: "/",
        title:"管理后台",
        component:asyncComponent(()=>import("../layout/BaLayout"))
        
    }



]