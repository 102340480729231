import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import routes from './config/routes';
// import app from './services/AppService';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment-timezone';
import 'moment/locale/zh-cn';
import { api } from './util/axios';
import { initSysConfig } from './config';
import AuthLoading from './components/AuthLoading';
import TimezoneProvier from "./components/TimezoneProvier"


moment.locale("zh-cn")

// moment.tz.setDefault("Asia/Shanghai");
// moment.tz.setDefault("Asia/Bangkok");

// app.run();


export default class App extends Component {


  state = {
    loading: true
  }

  loadConfig = () => {
    api("config").get().then(ret => initSysConfig(ret.data)).finally(() => this.setState({ loading: false }))
  }

  componentDidMount() {
    this.loadConfig();
  }

  render() {

    // console.log(process.env)

    if (this.state.loading) {
      return <AuthLoading />
    }


    return (

      <TimezoneProvier>

        <ConfigProvider locale={zhCN}  >
          <HashRouter>
            <Switch>
              {routes.map((route, index) => {
                //console.log("route=>",route)
                return (
                  <Route
                    key={index}
                    path={route.path}
                    render={props => {
                      const Page = route.component;
                      return Page ? (
                        <Page {...props} reloadConfig={this.loadConfig} />
                      ) : null
                    }}
                  // component={}

                  />
                )
              })}
            </Switch>
          </HashRouter>
        </ConfigProvider>
      </TimezoneProvier>
    );
  }

}
